'use strict';

var constants4 = require('../v4/constants.js');
var helpers = require('./helpers.js');
var sprintf = require('sprintf-js').sprintf;

/**
 * @returns {String} the address in link form with a default port of 80
 */
exports.href = function (optionalPort) {
  if (optionalPort === undefined) {
    optionalPort = '';
  } else {
    optionalPort = sprintf(':%s', optionalPort);
  }

  return sprintf('http://[%s]%s/', this.correctForm(), optionalPort);
};

/**
 * @returns {String} a link suitable for conveying the address via a URL hash
 */
exports.link = function (options) {
  if (!options) {
    options = {};
  }

  if (options.className === undefined) {
    options.className = '';
  }

  if (options.prefix === undefined) {
    options.prefix = '/#address=';
  }

  if (options.v4 === undefined) {
    options.v4 = false;
  }

  var formFunction = this.correctForm;

  if (options.v4) {
    formFunction = this.to4in6;
  }

  if (options.className) {
    return sprintf('<a href="%1$s%2$s" class="%3$s">%2$s</a>',
      options.prefix, formFunction.call(this), options.className);
  }

  return sprintf('<a href="%1$s%2$s">%2$s</a>', options.prefix,
    formFunction.call(this));
};

/**
 * Groups an address
 * @returns {String}
 */
exports.group = function () {
  var address4 = this.address.match(constants4.RE_ADDRESS);
  var i;

  if (address4) {
    // The IPv4 case
    var segments = address4[0].split('.');

    this.address = this.address.replace(constants4.RE_ADDRESS,
      sprintf('<span class="hover-group group-v4 group-6">%s</span>' +
        '.' +
        '<span class="hover-group group-v4 group-7">%s</span>',
        segments.slice(0, 2).join('.'),
        segments.slice(2, 4).join('.')));
  }

  if (this.elidedGroups === 0) {
    // The simple case
    return helpers.simpleGroup(this.address);
  }

  // The elided case
  var output = [];

  var halves = this.address.split('::');

  if (halves[0].length) {
    output.push(helpers.simpleGroup(halves[0]));
  } else {
    output.push('');
  }

  var classes = ['hover-group'];

  for (i = this.elisionBegin;
       i < this.elisionBegin + this.elidedGroups; i++) {
    classes.push(sprintf('group-%d', i));
  }

  output.push(sprintf('<span class="%s"></span>', classes.join(' ')));

  if (halves[1].length) {
    output.push(helpers.simpleGroup(halves[1], this.elisionEnd));
  } else {
    output.push('');
  }

  return output.join(':');
};
