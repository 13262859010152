'use strict';

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = debounceAction;

var _lodash = require('lodash.debounce');

var _lodash2 = _interopRequireDefault(_lodash);

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }

function _toConsumableArray(arr) { if (Array.isArray(arr)) { for (var i = 0, arr2 = Array(arr.length); i < arr.length; i++) { arr2[i] = arr[i]; } return arr2; } else { return Array.from(arr); } }

function debounceAction(action, wait, options) {
  // for options see: https://lodash.com/docs/4.17.4#debounce
  var debounced = (0, _lodash2.default)(function (dispatch, actionArgs) {
    return dispatch(action.apply(undefined, _toConsumableArray(actionArgs)));
  }, wait, options);

  // see: https://github.com/gaearon/redux-thunk
  var thunk = function thunk() {
    for (var _len = arguments.length, actionArgs = Array(_len), _key = 0; _key < _len; _key++) {
      actionArgs[_key] = arguments[_key];
    }

    return function (dispatch) {
      return debounced(dispatch, actionArgs);
    };
  };

  // provide hook to _.debounce().cancel() to cancel any trailing invocations
  thunk.cancel = debounced.cancel;

  return thunk;
}
